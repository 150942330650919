import {
  type CommonMedia,
  type EnumsGamePackAudience,
  type EnumsGamePackCompetitionLevel,
  type EnumsGamePackDifficulty,
  type EnumsGamePackPlayStyle,
  type EnumsGamePackVersion,
  type ModelsAIHostSettings,
  type ModelsAssessmentSettings,
  type ModelsBlockVoteStats,
  type ModelsCohostSettings,
  type ModelsDetailSettings,
  type ModelsExtraSettings,
  type ModelsMarketingSettings,
  type ModelsPlaybackSettings,
  type ModelsUgcSettings,
} from '@lp-lib/api-service-client/public';
import {
  type Block,
  type BlockFields,
  type RandomizerBlock,
} from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import { type ISO8601String } from './api';
import { type Tag } from './tag';
import { type BaseUser } from './user';

export type GameLikeType = 'game' | 'gamePack';

export type GameLikeCollectionType =
  | 'featured'
  | 'free'
  | 'templates'
  | 'zoomFree';

export type GameLikeBase = {
  id: string;
  name: string;
  description: string | null;
  cover?: Media | null;
  uid: string;
  creator: BaseUser;
  isPrime: boolean;
  tags?: Tag[];
  approximateDurationSeconds: number;
  createdAt: ISO8601String;
  updatedAt: ISO8601String;
};

export type Game = GameLikeBase & {
  type: 'game';
  blocksCount: number;
  recordingSeconds: number;
  recordingsCount: number;
  playbackVersion: number;
  recordingVersionMismatch: boolean;

  blocks?: Block[] | null;
};

export type GamePack = GameLikeBase & {
  type: 'gamePack';
  venueBackgroundMediaData?: MediaData | null;
  venueBackground?: Media | null;
  childrenIds?: string[] | null;
  isFeatured: boolean;
  teamRandomizationSettings?: TeamRandomizationSettings | null;
  playerRange: PlayerRange;
  replayable: boolean;
  promotionalAssets: GamePackPromotionalAssets | null;
  version: EnumsGamePackVersion;
  playbackSettings?: ModelsPlaybackSettings | null;
  detailSettings?: ModelsDetailSettings | null;
  marketingSettings?: ModelsMarketingSettings | null;
  extraSettings?: ModelsExtraSettings | null;
  aiHostSettings?: ModelsAIHostSettings | null;
  cohostSettings?: ModelsCohostSettings | null;
  played?: boolean | null;
  blockVoteStats?: ModelsBlockVoteStats | null;
  slug: string;
  isUGCTemplate: boolean | null;
  ugcSettings?: ModelsUgcSettings | null;
  assessmentSettings?: ModelsAssessmentSettings | null;
  learnerCount?: number | null;
};

export interface GamePackPromotionalAssets {
  pushable: boolean;
  title: string;
  description: string;
  cover?: {
    mediaData: MediaData;
    media: Media;
  };
}

// Note(falcon): For now, these settings are equivalent to what is defined on the block.
export type TeamRandomizationSettings = BlockFields<RandomizerBlock>;

export type PlayerRange = { min: number; max?: number };

export type GameLike = Game | GamePack;

export interface GameLikeResponse<T extends GameLike> {
  instance: T;
}

export enum GameLikeQueryType {
  My = 'my',
  ByTags = 'by-tags',
  Search = 'search',
  Untagged = 'untagged',
  Played = 'played',
}

export enum GameActionType {
  CreateGame = 'CreateGame',
  AddAllBlocks = 'AddAllBlocks',
  AddBlocks = 'AddBlocks',
  PublishGame = 'PublishGame',
  CopyPrimeGame = 'CopyPrimeGame',
}

export enum GamePackActionType {
  CreateGamePack = 'CreateGamePack',
  PublishGamePack = 'PublishGamePack',
  CopyPrimeGamePack = 'CopyPrimeGamePack',
}

export type GameLikeEditingContext = 'create' | 'edit' | 'publish';

export const GameLikeLabelMap: { [t in GameLikeType]: string } = {
  game: 'Minigame',
  gamePack: 'Game Pack',
};

type GameLikeActionSheetType =
  | 'edit'
  | 'duplicate'
  | 'publish'
  | 'delete'
  | 'addToMySpace'
  | 'feature'
  | 'pin'
  | 'preview'
  | 'export'
  | 'copyPublicLink'
  | 'copyEditLink'
  | 'copyMyVenueLink'
  | 'editPromotionalAssets'
  | 'convertToBrand'
  | 'testGamePack'
  | 'cohostGamePack'
  | 'editSlides'
  | 'craftSlidesShareLink'
  | 'transferOwnership';

export type GameLikeActionSheetSettings = {
  [key in GameLikeActionSheetType]?: boolean;
};

type GameLikeAdvancedOperation = 'feature' | 'free';

export type GameLikeAdvancedDropdownSettings = {
  [key in GameLikeAdvancedOperation]?: boolean;
};

// note(falcon): unfortunately, not automatically codegen'd because the backend endpoint passes the filtersdto
// as query params. afaict there's no way to use `@Param` with a struct for query params.
export enum EnumsGamePackPlayHistoryFilter {
  GamePackPlayHistoryFilterPlayed = 'played',
  GamePackPlayHistoryFilterNotPlayed = 'not_played',
}

export type GameLikeFilterDuration = {
  min: number;
  // note: this differs from the backend DTO, but existed in the old frontend code. maintaining it for now.
  // the backend will set a zero value, and treats 0 as unbounded.
  max?: number;
};

export type GameLikeFilters = {
  duration?: GameLikeFilterDuration[];
  difficulty?: EnumsGamePackDifficulty[];
  competitionLevel?: EnumsGamePackCompetitionLevel[];
  audience?: EnumsGamePackAudience[];
  playHistory?: EnumsGamePackPlayHistoryFilter[];
  playerCount?: number;
  playStyle?: EnumsGamePackPlayStyle[];
};
///

export type GamePackShowcaseCardData = {
  primaryText: string;
  secondaryText: string;
  hint?: string;
};

export type GamePackShowcaseCard = GamePackShowcaseCardData & {
  id: string;
  media?: CommonMedia;
};
